
interface AnalyticsReportStatVarianceProps {
    thisPeriodValue: number;
    comparisonPeriodValue: number;
}

const AnalyticsReportStatVariance = (props: AnalyticsReportStatVarianceProps) => {
    const { thisPeriodValue, comparisonPeriodValue } = props;

    if (comparisonPeriodValue === 0) return <div style={{ border: '0' }}>&nbsp;</div>;
    let percentage = 100 - ((comparisonPeriodValue / thisPeriodValue) * 100);
    const isIncrease = percentage > 0;
    const colourClass = isIncrease ? 'positive' : 'negative'
    return <div style={{ border: '0' }} className={`text-right analytics-report-stat ${colourClass}`}>{isIncrease ? '+' : ''}{percentage.toFixed(2)}%</div> 
}

export default AnalyticsReportStatVariance;