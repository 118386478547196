
import * as PropTypes from 'prop-types'
import { AnalyticsRevenueBucket, VenueRevenueStats } from "./../types";
import { ITranslationContext } from '../../../../translations';
import { RevenueReportSettings } from '../../../../store/pages/analytics/types';

interface RevenueReportDataTableProps {
    settings: RevenueReportSettings;
    venues: VenueRevenueStats[];
    showComparison: boolean;
    formatPeriodHeading: (period: AnalyticsRevenueBucket) => string;
}

const RevenueReportDataTable = (props: RevenueReportDataTableProps, context: ITranslationContext) => {
    const { settings, venues, showComparison, formatPeriodHeading } = props;
    const { t } = context;
    if (!settings.tableGrossValues && !settings.tableNetValues && !settings.tableBookings && !settings.tableOtherBills) {
        return null;
    }

    return <>{venues
        .map(v => <div key={`${v.venueId}_table_wrapper`} className='analytics-report-data-table'>
            {venues.length > 1 ? <h4 style={{ backgroundColor: v.colour }}>{v.venueName}</h4> : null}
            <table className='table table-striped table-sm table-condensed'>
                <thead>
                    <tr key='main-header'>
                        <th></th>
                        {settings.tableGrossValues ? <th colSpan={showComparison ? 3 : 1}>{t('RevenueReportDataTable:grossValues')}</th> : null}
                        {settings.tableNetValues ? <th colSpan={showComparison ? 3 : 1}>{t('RevenueReportDataTable:netValues')}</th> : null}
                        {settings.tableBookings ? <th colSpan={showComparison ? 3 : 1}>{t('RevenueReportDataTable:bookings')}</th> : null}
                        {settings.tableOtherBills ? <th colSpan={showComparison ? 3 : 1}>{t('RevenueReportDataTable:otherBills')}</th> : null}
                    </tr>
                    {showComparison
                        ? <tr key='sub-header'>
                            <th></th>
                            {settings.tableGrossValues ? <th className='bordered'>{t('RevenueReportDataTable:thisPeriod')}</th> : null}
                            {settings.tableGrossValues ? <th colSpan={2}>{t('RevenueReportDataTable:comparedTo')}</th> : null}
                            {settings.tableNetValues ? <th className='bordered'>{t('RevenueReportDataTable:thisPeriod')}</th> : null}
                            {settings.tableNetValues ? <th colSpan={2}>{t('RevenueReportDataTable:comparedTo')}</th> : null}
                            {settings.tableBookings ? <th className='bordered'>{t('RevenueReportDataTable:thisPeriod')}</th> : null}
                            {settings.tableBookings ? <th colSpan={2}>{t('RevenueReportDataTable:comparedTo')}</th> : null}
                            {settings.tableOtherBills ? <th className='bordered'>{t('RevenueReportDataTable:thisPeriod')}</th> : null}
                            {settings.tableOtherBills ? <th colSpan={2}>{t('RevenueReportDataTable:comparedTo')}</th> : null}
                        </tr>
                        : null}
                </thead>
                <tbody>
                    {v.thisPeriodStats.periodBuckets.map((ps, ix) => {
                        const comparisonValues = v.comparisonPeriodStats.periodBuckets[ix] ? v.comparisonPeriodStats.periodBuckets[ix] : null;
                        return <tr key={`${v.venueId}_${ix}`}>
                            <th className='row-heading'>{formatPeriodHeading(ps)}</th>
                            {settings.tableGrossValues ? <td className='bordered'>{ps.grossRevenue.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                            {settings.tableGrossValues && showComparison ? <td>{comparisonValues ? comparisonValues.grossRevenue.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode }) : null}</td> : null}
                            {settings.tableGrossValues && showComparison ? <td>{formatComparison(ps.grossRevenue, comparisonValues ? comparisonValues.grossRevenue : null)}</td> : null}

                            {settings.tableNetValues ? <td className='bordered'>{ps.netRevenue.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                            {settings.tableNetValues && showComparison ? <td>{comparisonValues ? comparisonValues.netRevenue.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode }) : null}</td> : null}
                            {settings.tableNetValues && showComparison ? <td>{formatComparison(ps.netRevenue, comparisonValues ? comparisonValues.netRevenue : null)}</td> : null}

                            {settings.tableBookings ? <td className='bordered'>{ps.bookings.toFixed(0)}</td> : null}
                            {settings.tableBookings && showComparison ? <td>{comparisonValues ? comparisonValues.bookings.toFixed(0) : null}</td> : null}
                            {settings.tableBookings && showComparison ? <td>{formatComparison(ps.bookings, comparisonValues ? comparisonValues.bookings : null)}</td> : null}

                            {settings.tableOtherBills ? <td className='bordered'>{ps.otherBills.toFixed(0)}</td> : null}
                            {settings.tableOtherBills && showComparison ? <td>{comparisonValues ? comparisonValues.otherBills.toFixed(0) : null}</td> : null}
                            {settings.tableOtherBills && showComparison ? <td>{formatComparison(ps.otherBills, comparisonValues ? comparisonValues.otherBills : null)}</td> : null}
                        </tr>
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <th className='row-heading'>{t('Global:totals')}</th>
                        {settings.tableGrossValues ? <td className='bordered'>{v.thisPeriodStats.totalRevenueThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                        {settings.tableGrossValues && showComparison ? <td>{v.comparisonPeriodStats.totalRevenueThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                        {settings.tableGrossValues && showComparison ? <td>{formatComparison(v.thisPeriodStats.totalRevenueThisPeriod, v.comparisonPeriodStats.totalRevenueThisPeriod)}</td> : null}

                        {settings.tableNetValues ? <td className='bordered'>{v.thisPeriodStats.netRevenueThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                        {settings.tableNetValues && showComparison ? <td>{v.comparisonPeriodStats.netRevenueThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                        {settings.tableNetValues && showComparison ? <td>{formatComparison(v.thisPeriodStats.netRevenueThisPeriod, v.comparisonPeriodStats.netRevenueThisPeriod)}</td> : null}

                        {settings.tableBookings ? <td className='bordered'>{v.thisPeriodStats.numberOfBookingsThisPeriod.toFixed(0)}</td> : null}
                        {settings.tableBookings && showComparison ? <td>{v.comparisonPeriodStats.numberOfBookingsThisPeriod.toFixed(0)}</td> : null}
                        {settings.tableBookings && showComparison ? <td>{formatComparison(v.thisPeriodStats.numberOfBookingsThisPeriod, v.comparisonPeriodStats.numberOfBookingsThisPeriod)}</td> : null}

                        {settings.tableOtherBills ? <td className='bordered'>{v.thisPeriodStats.numberOfNonBookingBillsThisPeriod.toFixed(0)}</td> : null}
                        {settings.tableOtherBills && showComparison ? <td>{v.comparisonPeriodStats.numberOfNonBookingBillsThisPeriod.toFixed(0)}</td> : null}
                        {settings.tableOtherBills && showComparison ? <td>{formatComparison(v.thisPeriodStats.numberOfNonBookingBillsThisPeriod, v.comparisonPeriodStats.numberOfNonBookingBillsThisPeriod)}</td> : null}
                    </tr>
                </tfoot>
            </table>
        </div>)}</>
}

const formatComparison = (thisPeriodValue: number, comparisonPeriodValue: number | null) => {
    if (comparisonPeriodValue !== null && !isNaN(comparisonPeriodValue) && thisPeriodValue > 0) {
        const percentage = 100 - ((comparisonPeriodValue / thisPeriodValue) * 100);
        const isIncrease = percentage > 0;
        const colourClass = isIncrease ? 'positive' : 'negative'
        return <span className={colourClass}>{isIncrease ? '+' : ''}{percentage.toFixed(2)}%</span>
    }
    return <span></span>
}

RevenueReportDataTable.contextTypes = {
    t: PropTypes.func
}

export default RevenueReportDataTable;

