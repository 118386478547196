

export const formatComparison = (thisPeriodValue: number, comparisonPeriodValue: number | null): JSX.Element => {
    if (comparisonPeriodValue !== null && !isNaN(comparisonPeriodValue) && thisPeriodValue > 0) {
        const percentage = 100 - ((comparisonPeriodValue / thisPeriodValue) * 100);
        const isIncrease = percentage > 0;
        const colourClass = isIncrease ? 'positive' : 'negative'
        return <span className={ colourClass }> { isIncrease? '+': '' }{ percentage.toFixed(2) }% </span>
    }
    return <span></span>
}