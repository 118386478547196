
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../store/apiClient';

import { ProductCategory } from '../../../store/pages/productCategories/types';
import { Product, ProductPrice, ProductPricingMode, ProductType } from '../../../store/pages/products/types';
import BillPanel from './billPanel';
import { Bill, BillItem, BillPayment, CreateBillItem, createScheduledBillPayment, AdditionalPaymentInfo, deserializeBill, formatItemDescription, formatItemName, PaymentStatus, BillFee, BillRefund, BillItemVoucher } from '../../../store/pages/pointOfSale/types';
import { CustomerSearchResult } from '../../../store/pages/diary/types';
import { PaymentMethod, PaymentMethodType } from '../../../store/pages/paymentMethods/types';
import CategoriesPanel from './categoriesPanel';
import ProductsPanel from './productsPanel';
import { isNullOrEmpty, clickHandler, generateTempId } from '../../../utils/util';
import EditBillItem from './editBillItem';
import AddBillDiscount from './addBillDiscount';
import PointOfSalePaymentsPanel from './pointOfSalePaymentPanel';
import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import Loading from '../../global/loading';
import { ValidationError } from '../../../store/global/types';
import PaymentExtraInfo from './paymentExtraInfo';
import ValidateVoucherForPayment from './validateVoucherForPayment';
import CustomerSearch from '../diary/customerSearch';
import EventCustomerForm, { CustomerDetails } from '../diary/eventCustomerForm';
import { Gender, MarketingPreference } from '../../../store/pages/customer/types';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { Booking } from '../diary/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { ActivityFormatProduct, getActivityProducts, getPrices } from '../diary/helpers';
import ProcessGatewayPayment from './gatewayPayments/processGatewayPayment';
import EditScheduledPayment from '../pointOfSale/editScheduledPayment';
import EditPayment from '../pointOfSale/editPayment';
import EditBillFee from './editBillFee';
import AddBillFee from './addBillFee';
import { Fee } from '../../../store/pages/fees/types';
import { TaxRate } from '../../../store/pages/taxRates/types';
import EditBillRefund from './editBillRefund';
import { Promotion } from '../../../store/pages/promotions/types';
import { findLinkedBillItems, ICreateContactResponse, ISendEmailResponse, LinkedItemQuantities, PosProductCategory } from './types';
import SendVouchersForm from './sendVouchersForm';
import { VoucherProduct } from '../../../store/pages/vouchers/types';
import VoucherDetails from '../vouchers/voucherDetails';
import { MembershipType } from '../../../store/pages/memberships/types';
import SendMembershipEmailOverlay from './sendMembershipEmailOverlay';

export type BillInfo = string | CreateBillItem[];

interface PointOfSalePanelProps {
    venue: Venue;
    productCategories: ProductCategory[];
    products: Product[];
    customerCategories: CustomerCategory[];
    eventProducts: ActivityFormatProduct[];
    activityFormats: ActivityFormat[];
    paymentMethods: PaymentMethod[];
    fees: Fee[];
    taxRates: TaxRate[];
    promotions: Promotion[];
    vouchers: VoucherProduct[];
    membershipTypes: MembershipType[];
    booking: Booking | null;
    customerId?: string;
    billInfo: BillInfo;
    paymentId?: string | null;
    showPayments?: boolean;
    showRefund?: boolean;
    paymentAmount?: number;
    posSessionComplete: (bill: Bill) => void;
    logout: () => void;
}

interface PointOfSalePanelState {
    selectedCategory: PosProductCategory | null;
    billId: string | null;
    bill: Bill | null;
    saving: boolean;
    saveError: api.ApiError | null;
    saveValidationErrors: ValidationError[];
    paymentError: api.ApiError | null;
    showOverlay: boolean;
    showPayments: boolean;
    recordPayment: (billKey: string, paymentMethod: PaymentMethod, amount: number, paymentId: string | null, text: string | null, paymentComplete: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => void;
    payment: BillPayment | null;
    isRefund: boolean;
    overlayComponent?: JSX.Element | null;
    tillIntegrationAvailable: boolean;
    tillVersion: string | null;
    deviceName: string | null;
    paymentInProgress: boolean;
    productCategories: PosProductCategory[];
}

interface IContext {
    t: (val: string) => string;
}

interface IFindBillResponse {
    bill: Bill;
}

export interface IMakePaymentResponse {
    bill: Bill;
}

export interface ISaveBillResponse {
    bill: Bill;
}

interface TillStatusResponse {
    version: string | null;
    machineName: string | null;
}

export default class PointOfSalePanel extends React.Component<PointOfSalePanelProps, PointOfSalePanelState> {

    constructor(props: PointOfSalePanelProps) {
        super(props);

        //const productCategories = this.buildProductCategories(props.venue.id, props.productCategories, props.products, props.eventProducts, props.vouchers, props.membershipTypes);

        const category = null;
        const paymentId = props.paymentId;

        this.state = {
            bill: null,
            billId: typeof props.billInfo === "string" ? props.billInfo : null,
            saving: false,
            saveError: null,
            saveValidationErrors: [],
            paymentError: null,
            selectedCategory: category,
            showOverlay: false,
            overlayComponent: null,
            showPayments: props.showPayments || props.showRefund || paymentId ? true : false,
            recordPayment: props.showRefund
                ? (billKey: string, paymentMethod: PaymentMethod, amount: number, paymentId: string | null, text: string | null, paymentComplete: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => this.makeRefund(billKey, paymentMethod, amount, text, paymentComplete)
                : paymentId
                    ? this.makeScheduledPayment
                    : this.payBill,
            payment: null,
            isRefund: props.showRefund ? true : false,
            tillIntegrationAvailable: false,
            tillVersion: null,
            deviceName: null,
            paymentInProgress: false,
            productCategories: []
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        fetch('http://localhost:6715/api/till')
            .then(res => res.ok ? res.json() : Promise.resolve({ version: null, machineName: null }))
            .then(resp => {
                const response = resp as TillStatusResponse;
                const ok = response.version ? true : false;
                const version = response.version ? response.version : null;
                const machineName = response.machineName ? response.machineName : null;
                this.setState({ tillIntegrationAvailable: ok, tillVersion: version, deviceName: machineName });
            })
            .catch(e => this.setState({ tillIntegrationAvailable: false, deviceName: null, tillVersion: null }));

        const { venue, booking, customerId, billInfo, paymentId } = this.props;
        const { billId } = this.state;
        if (!billId || isNullOrEmpty(billId)) {
            this.createBill(venue.id, booking ? booking.id : null, billInfo as CreateBillItem[], customerId);
        } else {
            this.loadBill(billId, (bill: Bill) => {
                const payment = paymentId ? bill.payments.find(p => p.id === paymentId) : null;
                this.setState({
                    billId: bill.id,
                    bill: bill,
                    payment: payment ? payment : null
                })
            });
        }
    }


    componentDidUpdate(prevProps: PointOfSalePanelProps, prevState: PointOfSalePanelState) {

        if (prevProps.productCategories.length !== this.props.productCategories.length
            || prevProps.products.length !== this.props.products.length
            || prevProps.eventProducts.length !== this.props.eventProducts.length
            || prevProps.vouchers.length !== this.props.vouchers.length
            || prevProps.membershipTypes.length !== this.props.membershipTypes.length
            || prevState.productCategories.length === 0 ) {

            this.setState((s, p) => {
                const productCategories = this.buildProductCategories(p.venue.id, p.productCategories.filter(c => !c.archived && c.showOnPointOfSale), p.products, p.vouchers, p.membershipTypes);
                const selectedCategory = productCategories.findIndex(c => s.selectedCategory && c.id === s.selectedCategory.id) < 0 ? productCategories[0] : s.selectedCategory;

                return { selectedCategory: selectedCategory, productCategories: productCategories };
            });
        }
    }

    buildProductCategories = (venueId: string, productCategories: ProductCategory[], products: Product[], vouchers: VoucherProduct[], membershipTypes: MembershipType[]) => {
        const { t } = this.context;
        const categories = productCategories
            .filter(c => !c.archived && c.showOnPointOfSale && c.venueId === venueId)
            .map(c => ({ id: c.id, name: c.name, colour: c.colour, isEventCategory: c.id === 'event_specific', isVoucherCategory: false, isMembershipCategory: false }));

        if (products.findIndex(p => p.type === ProductType.Voucher && vouchers.findIndex(v => v.productId === p.id && v.venueSettings.findIndex(vs => vs.venueId === venueId && vs.sellOnPointOfSale) >= 0) >= 0) >= 0) {
            categories.push({ id: '__vouchers__', name: t('PointOfSale:voucherSpecificProductCategory'), colour: '#337ab7', isEventCategory: false, isVoucherCategory: true, isMembershipCategory: false });
        }

        if (products.findIndex(p => membershipTypes.findIndex(mt => mt.productId === p.id && mt.venuePurchaseSettings.findIndex(vs => vs.venueId === venueId && vs.sellOnPointOfSale) >= 0) >= 0) >= 0) {
            categories.push({ id: '__memberships__', name: t('PointOfSale:membershipSpecificProductCategory'), colour: '#337ab7', isEventCategory: false, isVoucherCategory: false, isMembershipCategory: true });
        }

        return categories;
    }

    loadBill = (billId: string, callback: (bill: Bill) => void) => {
        api.getWithAuth<IFindBillResponse>(`api/v1/bill/${billId}`, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(result => {
                const bill = deserializeBill(result.bill);
                callback(bill);
            }, err => ({ /* TODO: Handle error*/ }));
    }

    categorySelected = (categoryId: string) => this.setState(s => {
        const catIx = s.productCategories.findIndex(c => c.id === categoryId);
        return { selectedCategory: catIx >= 0 ? s.productCategories[catIx] : null }
    });

    canUpdateItem = (item: BillItem | undefined, product: Product | null | undefined, productPrice: ProductPrice | null) => {
        if (!item || !product) return false;
        if (product.type === ProductType.Membership) return false;
        if (product.type === ProductType.Voucher) return false
        return true;
    }

    productSelected = (productId: string, customerCategoryId: string | null, selectedCategory: PosProductCategory | null, quantity: number, reservationId: string | null) => {
        const { bill, saving } = this.state;
        const { eventProducts, products, booking } = this.props;

        if (!bill || saving) return;

        const item = bill.items.find(i => i.productId === productId && i.customerCategoryId === customerCategoryId && (reservationId === null || i.reservationId === reservationId) && i.vouchers.length === 0);
        const reservationProd = eventProducts.find(ep => ep.reservationId === reservationId && selectedCategory != null && selectedCategory.isEventCategory && ep.product.id === productId && ep.customerCategoryId === customerCategoryId);
        const product = reservationProd && reservationProd.product ? reservationProd.product : products.find(p => p.id === productId);
        const pricingMode = product ? product.pricingMode : ProductPricingMode.PerUnit;
        var productPrice = product ? getPrices(product, quantity, booking ? booking.createDateTimeInLocalTime : new Date(), booking ? booking.firstEventStartDateTime : null, bill.customerTags)[0].productPrice : null;

        var canUpdateItem = this.canUpdateItem(item, product, productPrice);

        if (item && canUpdateItem) {
            this.updateBillItem(item, item.quantity + quantity, false, item.unitPrice, item.productId, item.placesToBookPerUnit, item.reservationId, item.customerCategoryId, item.pricingMode, null, item.productPriceId, [], this.closeOverlay);
        } else {
            // see if there is a reservation to attach this item  to
            const productPriceId = productPrice ? productPrice.id : null;
            this.createBillItem(productId, quantity, reservationProd ? reservationProd.placesPerUnit : null, reservationProd ? reservationProd.reservationId : null, reservationProd ? reservationProd.customerCategoryId : null, pricingMode, productPriceId, this.closeOverlay);
        }
    }

    updateItem = (itemKey: string, quantity: number, unitPrice: number, productId: string | null, placesToBookPerUnit: number | null, reservationId: string | null, customerCategoryId: string | null, pricingMode: ProductPricingMode, fixedPriceOverride: number | null, productPriceId: string, linkedItemQuantities: LinkedItemQuantities[]) => {
        const { bill } = this.state;

        if (!bill) return;

        const item = bill.items.find(i => i.key === itemKey);
        if (item) {
            this.updateBillItem(item, quantity, false, unitPrice, productId, placesToBookPerUnit, reservationId, customerCategoryId, pricingMode, fixedPriceOverride, productPriceId, linkedItemQuantities, this.closeOverlay);
        }
    }

    removeItem = (itemKey: string) => {
        const { bill } = this.state;
        if (!bill) return;

        const item = bill.items.find(i => i.key === itemKey);
        if (item) {
            this.updateBillItem(item, item.quantity, true, item.unitPrice, item.productId, item.placesToBookPerUnit, item.reservationId, item.customerCategoryId, item.pricingMode, item.totalItemPrice, item.productPriceId, [], this.closeOverlay);
        }
    }

    payBill = (billId: string, paymentMethod: PaymentMethod, amount: number, paymentId: string | null, text: string | null, paymentComplete: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => {
        const { bill, deviceName } = this.state;
        const { venue, customerId } = this.props;

        if (!bill || bill.id !== billId)
            return;

        const payment = this.createBillPayment(paymentMethod, amount, deviceName, customerId ? customerId : null);
        if (paymentMethod.type === PaymentMethodType.Voucher) {
            this.validateVoucher(venue.id, paymentMethod, billId, payment, (voucherCode, amount, callback) => this.redeemVoucher(voucherCode, amount, payment.id, paymentMethod, (success, bill, error) => {
                if (callback) {
                    callback(success, bill, error);
                }
                if (success) {
                    this.closeOverlay();
                    paymentComplete(success, bill, error);
                }
            }));
        } else if (paymentMethod.extraFields && paymentMethod.extraFields.length > 0) {
            this.collectExtraInfo(paymentMethod, payment, additionalInfo => this.saveNewPayment({ ...payment, additionalInfo: additionalInfo }, paymentMethod, (success, bill, error) => {
                if (success) this.closeOverlay();
                paymentComplete(success, bill, error);
            }));
        } else {
            this.saveNewPayment(payment, paymentMethod, paymentComplete);
        }
    }

    makeScheduledPayment = (billId: string, paymentMethod: PaymentMethod, amount: number, paymentId: string | null, text: string | null, paymentComplete: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => {
        const { bill, payment } = this.state;
        const { venue } = this.props;
        if (!bill || bill.id !== billId || !payment)
            return;

        if (paymentMethod.type === PaymentMethodType.Voucher) {
            this.validateVoucher(venue.id, paymentMethod, billId, payment, (voucherCode, amount) => this.redeemVoucher(voucherCode, amount, paymentId, paymentMethod, (success, bill, error) => {
                if (success) this.closeOverlay();
                paymentComplete(success, bill, error);
            }));
        } else if (paymentMethod.extraFields && paymentMethod.extraFields.length > 0) {
            this.collectExtraInfo(paymentMethod, payment, additionalInfo => this.recordPayment(payment.id, paymentMethod.id, amount, null, additionalInfo, (success, bill, error) => {
                if (success) this.closeOverlay();
                paymentComplete(success, bill, error);
            }));
        } else {
            this.recordPayment(payment.id, paymentMethod.id, amount, null, [], paymentComplete);
        }
    }

    schedulePayment = (billId: string, amount: number, description: string, dueDate: Date, isSecurityPayment: boolean, paymentComplete: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => {
        const { bill } = this.state;
        const { customerId } = this.props;

        if (!bill || bill.id !== billId)
            return;

        this.saveNewPayment(createScheduledBillPayment(amount, description, dueDate, isSecurityPayment, customerId ? customerId : null), null, paymentComplete);
    }

    payDeposit = (billId: string, paymentMethod: PaymentMethod, amount: number, paymentDescription: string | null, paymentComplete: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => {
        const { bill, deviceName } = this.state;
        const { customerId } = this.props;

        if (!bill || bill.id !== billId)
            return;

        const payment = this.createBillPayment(paymentMethod, amount, deviceName, customerId ? customerId : null);
        if (paymentMethod.extraFields && paymentMethod.extraFields.length > 0) {
            this.collectExtraInfo(paymentMethod, payment, additionalInfo => this.saveNewPayment({ ...payment, additionalInfo: additionalInfo }, paymentMethod, (success, bill, error) => {
                if (success) this.closeOverlay();
                paymentComplete(success, bill, error);
            }));
        } else {
            this.saveNewPayment(payment, paymentMethod, paymentComplete);
        }
    }

    createBillPayment = (paymentMethod: PaymentMethod, amount: number, deviceName: string | null, customerId: string | null): BillPayment => {
        const { bill } = this.state;
        const { t } = this.context;

        const hasPayment = bill && bill.payments && bill.payments.length > 0;
        const isSecurityPayment = !hasPayment && bill && amount < bill.outstandingBalance;
        const paymentDescription = isSecurityPayment ? t('PointOfSale:deposit') : null;

        return {
            id: '',
            key: generateTempId(),
            paymentMethodId: paymentMethod.id,
            status: PaymentStatus.Success,
            scheduled: false,
            paid: true,
            amount: amount,
            description: paymentDescription,
            isSecurityPayment: isSecurityPayment ? true : false,
            payentTakenDateTime: new Date().asUTC(),
            paymentDeviceName: deviceName,
            paymentTakenBy: null,
            paymentTakenById: null,
            paymentDueDate: null,
            paidByCustomerId: customerId,
            paidByCustomerName: null,
            customerStoredPaymentMethodId: null,
            paymentError: null,
            paymentMethodName: paymentMethod.name,
            receipt: null,
            takePaymentAutomatically: false,
            createdBy: '',
            createDateTime: new Date(),
            deleted: false,
            voucherCode: null,
            additionalInfo: [],
            gatewayPayments: []
        };
    }

    makeRefund = (billId: string, paymentMethod: PaymentMethod, amount: number, refundReason: string | null, completionCallback: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => {
        const { bill } = this.state;
        const { venue } = this.props;

        if (!bill || bill.id !== billId)
            return;

        this.setState({ saving: true });

        api.postWithAuth(`api/v1/bill/${billId}/refund`, { venueId: venue.id, billId: bill.id, paymentMethodId: paymentMethod.id, amount: amount, reason: refundReason  }, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(response => {
                const mpr = response.response as IMakePaymentResponse;
                if (mpr) {
                    const mappedBill = deserializeBill(mpr.bill);
                    this.setState({ saving: false, billId: mpr.bill.id, bill: mappedBill, paymentError: null });
                    completionCallback(true, mappedBill, null);
                }
            }, (err: api.ApiError) => {
                    this.setState({ saving: false, paymentError: err, saveValidationErrors: err.validationErrors }, () => completionCallback(false, null, err));
            });
    }

    saveNewPayment = (payment: BillPayment, paymentMethod: PaymentMethod | null, completionCallback: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => {
        this.setState({ saving: true });
        const { billId } = this.state;

        const isGatewayPayment = paymentMethod && paymentMethod.paymentGatewayId && paymentMethod.paymentGatewayId > 0;

        if (paymentMethod && isGatewayPayment) {
            this.initiateGatewayPayment(billId || '', payment, payment.amount, paymentMethod, completionCallback);
        } else {
            this.createPayment(billId || '', payment, completionCallback);
        }
    }

    redeemVoucher = (voucherCode: string, amount: number, paymentId: string | null, paymentMethod: PaymentMethod, completionCallback: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => {
        const { bill } = this.state;
        const { venue } = this.props;

        if (!bill)
            return;

        this.setState({ saving: true });

        api.postWithAuth(`api/v1/bill/${bill.id}/redeemVoucher`, { voucherCode: voucherCode, paymentMethodId: paymentMethod.id, scheduledPaymentId: paymentId, amountToRedeem: amount, overrideErrors: true }, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(response => {
                const mpr = response.response as IMakePaymentResponse;
                if (mpr) {
                    const mappedBill = deserializeBill(mpr.bill);
                    this.setState({ saving: false, billId: mpr.bill.id, bill: mappedBill, paymentError: null });
                    completionCallback(true, mappedBill, null);
                }
            }, (err: api.ApiError) => {
                this.setState({ saving: false, paymentError: err, saveValidationErrors: err.validationErrors }, () => completionCallback(false, null, err));
            });
    }

    initiateGatewayPayment = (billId: string, payment: BillPayment, amount: number, paymentMethod: PaymentMethod, completionCallback: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => {
        this.setState({ paymentInProgress: true });
        const { venue } = this.props;

        const errorHandler = (err: api.ApiError) => this.setState({ saving: false, paymentInProgress: false, saveError: err, saveValidationErrors: err.validationErrors }, () => completionCallback(false, null, err));

        const successCallback = (billId: string) => {
            this.loadBill(billId, (bill: Bill) => { 
                this.setState({ saving: false, paymentInProgress: false, billId: bill.id, bill: bill });
                completionCallback(true, bill, null);
            });
        }

        const cancelCallback = (billId: string) => {
            this.loadBill(billId, (bill: Bill) => {
                this.setState({ saving: false, paymentInProgress: false, billId: billId, bill: bill });
                this.closeOverlay();
                completionCallback(false, this.state.bill, null);
            });
        }

        this.showOverlay(<ProcessGatewayPayment venueId={venue.id} billId={billId} payment={payment} amount={amount} paymentMethod={paymentMethod} onComplete={successCallback} onCancelled={cancelCallback} onSaveError={errorHandler} />);
    }

    createPayment = (billId: string, payment: BillPayment, completionCallback: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => {
        const body = { ...payment, paymentDueDate: payment.paymentDueDate ? payment.paymentDueDate.toApiDateTimeString() : payment.paymentDueDate }
        api.postWithAuth(`api/v1/bill/${billId}/payment`, body, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(response => {
                const mpr = response.response as IMakePaymentResponse;
                if (mpr) {
                    const mappedBill = deserializeBill(mpr.bill);
                    this.setState({ saving: false, billId: mpr.bill.id, bill: mappedBill, saveError: null, paymentError: null });
                    completionCallback(true, mappedBill, null);
                }
            }, (err: api.ApiError) => {
                this.setState({ saving: false, saveError: null, paymentError: err, saveValidationErrors: err.validationErrors }, () => completionCallback(false, null, err));
            });
    }

    recordPayment = (paymentId: string, paymentMethodId: string, amount: number, voucherCode: string | null, additionalInfo: AdditionalPaymentInfo[], completionCallback: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => {
        this.setState({ saving: true });
        const { billId, deviceName, payment } = this.state;
        const { paymentMethods } = this.props;

        const paymentMethod = paymentMethods.find(pm => pm.id === paymentMethodId);
        if (!payment || !paymentMethod) {
            // show error
        } else {

            const isGatewayPayment = paymentMethod && paymentMethod.paymentGatewayId && paymentMethod.paymentGatewayId > 0;

            if (paymentMethod && isGatewayPayment) {
                this.initiateGatewayPayment(billId || '', payment, amount, paymentMethod, completionCallback);
            } else {
                this.updatePayment(billId || '', paymentId, paymentMethodId, amount, voucherCode, additionalInfo, deviceName, completionCallback);
            }
        }
    }

    updatePayment = (billId: string, paymentId: string, paymentMethodId: string, amount: number, voucherCode: string | null, additionalInfo: AdditionalPaymentInfo[], deviceName: string | null, completionCallback: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => {
        api.putWithAuth(`api/v1/bill/${billId}/payment/${paymentId}`, { paymentMethodId: paymentMethodId, amount: amount, paid: true, voucherCode: voucherCode, additionalInfo: additionalInfo, paymentDeviceName: deviceName }, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(response => {
                const mpr = response.response as IMakePaymentResponse;
                if (mpr) {
                    const mappedBill = deserializeBill(mpr.bill);
                    this.setState({ saving: false, billId: mpr.bill.id, bill: mappedBill, paymentError: null });
                    completionCallback(true, mappedBill, null);
                }
            }, (err: api.ApiError) => {
                    this.setState({ saving: false, paymentError: err, saveValidationErrors: err.validationErrors }, () => completionCallback(false, null, err));
            });
    }

    cancelPaymentAttempt = (bookingId: string, billPaymentId: string, gatewayPaymentId: string) => {
        const { logout } = this.props;
        this.setState({ saving: true });

        api.putWithAuth(`api/v1/bill/${bookingId}/payment/${billPaymentId}/cancelGatewayPayment/${gatewayPaymentId}`, {}, logout)
            .subscribe(response => {
                const mpr = response.response as ISaveBillResponse;
                if (mpr) {
                    const savedBill = deserializeBill(mpr.bill);
                    this.setState({ saving: false, billId: mpr.bill.id, bill: savedBill, paymentError: null });
                }
            }, (err: api.ApiError) => {
                    this.setState({ saving: false, paymentError: err, saveValidationErrors: err.validationErrors });
            });
    }

    createBillItem = (productId: string, quantity: number, placesToBookPerUnit: number | null, reservationId: string | null, customerCategoryId: string | null, pricingMode: ProductPricingMode, productPriceId: string | null, completionCallback: () => void) => {
        this.setState({ saving: true });
        const { billId } = this.state;
        api.postWithAuth(`api/v1/bill/${billId}/item`, { productId: productId, quantity: quantity, placesToBookPerUnit: placesToBookPerUnit, reservationId: reservationId, customerCategoryId: customerCategoryId, pricingMode: pricingMode, productPriceId: productPriceId }, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(response => {
                const mpr = response.response as ISaveBillResponse;
                if (mpr) {
                    this.setState({ saving: false, billId: mpr.bill.id, bill: deserializeBill(mpr.bill) });
                    completionCallback();
                }
            }, (err: api.ApiError) => {
                this.setState({ saving: false, saveError: err, saveValidationErrors: err.validationErrors });
            });
    }

    updateBillItem = (item: BillItem, quantity: number, archived: boolean, unitPrice: number, productId: string | null, placesToBookPerUnit: number | null, reservationId: string | null, customerCategoryId: string | null, pricingMode: ProductPricingMode, fixedPriceOverride: number | null, productPriceId: string, linkedItemQuantities: LinkedItemQuantities[], completionCallback: () => void) => {
        this.setState({ saving: true });
        const { billId } = this.state;
        api.putWithAuth(`api/v1/bill/${billId}/item/${item.id}`, {
            productId: productId,
            quantity: quantity,
            archived: archived,
            unitPrice: unitPrice,
            placesToBookPerUnit: placesToBookPerUnit,
            reservationId: reservationId,
            customerCategoryId: customerCategoryId,
            pricingMode: pricingMode,
            fixedPriceOverride: fixedPriceOverride,
            productPriceId: productPriceId,
            linkedItemQuantities: linkedItemQuantities
        }, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(response => {
                const mpr = response.response as ISaveBillResponse;
                if (mpr) {
                    this.setState({ saving: false, billId: mpr.bill.id, bill: deserializeBill(mpr.bill) });
                    completionCallback();
                }
            }, (err: api.ApiError) => {
                this.setState({ saving: false, saveError: err, saveValidationErrors: err.validationErrors });
            });
    }
    
    createBill = (venueId: string, bookingId: string | null, items: CreateBillItem[], customerId?: string) => {
        this.setState({ saving: true });
        api.postWithAuth(`api/v1/bill`, { venueId: venueId, bookingId: bookingId, customerId: customerId ? customerId : null, items: items }, this.props.logout)
            .subscribe(response => {
                const mpr = response.response as ISaveBillResponse;
                if (mpr) {
                    this.setState({ saving: false, billId: mpr.bill.id, bill: deserializeBill(mpr.bill) });
                }
            }, err => {
                this.setState({ saving: false, saveError: err, saveValidationErrors: err.validationErrors });
            });
    }

    saveBill = (bill: Bill, completionCallback: () => void) => {
        this.setState({ saving: true });
        const { logout } = this.props;
        const { billId } = this.state;

        const call = isNullOrEmpty(billId)
            ? api.postWithAuth(`api/v1/bill`, bill, logout)
            : api.putWithAuth(`api/v1/bill/${billId}`, bill, logout);

        call.subscribe(response => {
            const mpr = response.response as ISaveBillResponse;
            if (mpr) {
                this.setState({ saving: false, billId: mpr.bill.id, bill: deserializeBill(mpr.bill) });
                completionCallback();
            }
        }, (err: api.ApiError) => {
            this.setState({ saving: false, saveError: err, saveValidationErrors: err.validationErrors });
        });
    }

    addFee = (billId: string, fee: BillFee, callback: (success: boolean, error: api.ApiError | null) => void) => {
        this.setState({ saving: true });
        const { logout } = this.props;

        const call = api.postWithAuth(`api/v1/bill/${billId}/fee`, fee, logout);

        call.subscribe(response => {
            const mpr = response.response as ISaveBillResponse;
            if (mpr) {
                this.setState({ saving: false, billId: mpr.bill.id, bill: deserializeBill(mpr.bill) });
                callback(true, null);
            }
        }, (err: api.ApiError) => {
                this.setState({ saving: false });
                callback(false, err);
        });
    }

    updateBillFee = (billId: string, fee: BillFee, callback: (success: boolean, error: api.ApiError | null) => void) => {
        this.setState({ saving: true });
        const { logout } = this.props;

        const call = api.putWithAuth(`api/v1/bill/${billId}/fee/${fee.id}`, fee, logout);

        call.subscribe(response => {
            const mpr = response.response as ISaveBillResponse;
            if (mpr) {
                this.setState({ saving: false, billId: mpr.bill.id, bill: deserializeBill(mpr.bill) });
                callback(true, null);
            }
        }, (err: api.ApiError) => {
            this.setState({ saving: false });
            callback(false, err);
        });
    }

    voidRefund = (billId: string, refundId: string, voidReason: string, callback: (success: boolean, error: api.ApiError | null) => void) => {
        this.setState({ saving: true });
        const { logout, venue } = this.props;

        const call = api.putWithAuth(`api/v1/bill/${billId}/refund/${refundId}/void`, { venueId: venue.id, voidReason: voidReason }, logout);

        call.subscribe(response => {
            const mpr = response.response as ISaveBillResponse;
            if (mpr) {
                this.setState({ saving: false, billId: mpr.bill.id, bill: deserializeBill(mpr.bill) });
                callback(true, null);
            }
        }, (err: api.ApiError) => {
            this.setState({ saving: false });
            callback(false, err);
        });
    }

    setBillCustomer = (billId: string, customerId: string, completionCallback: () => void) => {
        this.setState({ saving: true });
        const { logout, venue } = this.props;

        if (isNullOrEmpty(billId)) {
            return;
        }

        const call = api.putWithAuth(`api/v1/bill/${billId}/customer`, { venueId: venue.id, customerId: customerId }, logout);

        call.subscribe(response => {
            const mpr = response.response as ISaveBillResponse;
            if (mpr) {
                this.setState({ saving: false, billId: mpr.bill.id, bill: deserializeBill(mpr.bill) });
                completionCallback();
            }
        }, (err: api.ApiError) => {
            this.setState({ saving: false, saveError: err, saveValidationErrors: err.validationErrors });
        });
    }

    collectExtraInfo = (paymentMethod: PaymentMethod, payment: BillPayment, save: (additionalInfo: AdditionalPaymentInfo[]) => void) => {
        this.showOverlay(<PaymentExtraInfo paymentMethod={paymentMethod} amount={payment.amount} completePayment={save} cancel={this.closeOverlay} />);
    }

    validateVoucher = (venueId: string, paymentMethod: PaymentMethod, billId: string, payment: BillPayment, save: (voucherCode: string, amount: number, completionCallback: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => void) => {
        this.showOverlay(<ValidateVoucherForPayment venueId={venueId} paymentMethod={paymentMethod} billId={billId} amount={payment.amount} redeemVoucher={save} cancel={this.closeOverlay} logout={this.props.logout} />);
    }

    getProductsForActivity = (item: BillItem): ActivityFormatProduct[] => {
        const { products, activityFormats, customerCategories } = this.props;
        const activity = activityFormats.find(a => a.id === item.activityFormatId);
        if (!activity)
            return [];

        return getActivityProducts(activity, item.reservationId, item.reservationStartTime, products, customerCategories);
    }

    editItem = (bill: Bill, item: BillItem) => {
        const { t } = this.context;
        const { products, customerCategories, venue } = this.props;
        const productSelections = this.getProductsForActivity(item);
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        this.showOverlay(<EditBillItem
            item={item}
            linkedItems={findLinkedBillItems(item, bill.items)}
            customerCategories={customerCategories}
            itemName={formatItemName(item, customerCategories, false, timeFormat, dateFormat, t)}
            customerTags={bill.customerTags}
            timeFormat={timeFormat}
            updateItem={this.updateItem}
            removeItem={this.removeItem}
            canUpdate={item.vouchers.length < 1}
            canRemove={!item.reservationId && item.vouchers.length < 1}
            cancel={this.closeOverlay}
            canSelectProduct={item.reservationId ? productSelections.length > 0 : false}
            productSelections={productSelections} products={products} />);
    }

    editPayment = (bill: Bill, payment: BillPayment) => {
        const { venue } = this.props;

        const billUpdated = (bill: Bill, closeOverlay: boolean) => {
            if (closeOverlay) this.closeOverlay();
            this.loadBill(bill.id, b => this.setState({ billId: bill.id, bill: bill, payment: null }));
        }

        const cancelPaymentAttempt = (billId: string, billPaymentId: string, gatewayPaymentId: string) => {
            this.cancelPaymentAttempt(billId, billPaymentId, gatewayPaymentId);
            this.closeOverlay();
        }

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        if (payment.paid || payment.void || payment.status === PaymentStatus.GatewayInProgress || payment.status === PaymentStatus.GatewayFailed || payment.status === PaymentStatus.GatewayCancelled) {
            this.showOverlay(<EditPayment
                venueId={venue.id}
                billId={bill.id}
                payment={payment}
                eventDate={bill.eventDate}
                timeFormat={timeFormat}
                dateFormat={dateFormat}
                paymentUpdated={billUpdated}
                cancelPaymentAttempt={(gatewayPaymentId: string) => cancelPaymentAttempt(bill.id, payment.id, gatewayPaymentId)}
                cancel={this.closeOverlay} />);
        } else {
            this.showOverlay(<EditScheduledPayment
                billId={bill.id}
                paymentId={payment.id}
                eventDate={bill.eventDate}
                amount={payment.amount}
                paymentDate={payment.paymentDueDate || new Date()}
                paymentDescription={payment.description || ''}
                timeFormat={timeFormat}
                dateFormat={dateFormat}
                paymentUpdated={b => billUpdated(b, true)}
                cancel={this.closeOverlay} />);
        }
    }

    editRefund = (bill: Bill, refund: BillRefund, timeFormat: TimeFormat, dateFormat: DateFormat) => {
        this.showOverlay(<EditBillRefund bill={bill} refund={refund} timeFormat={timeFormat} dateFormat={dateFormat} voidRefund={this.voidRefund} close={this.closeOverlay} />);
    }

    updateFeeAmount = (billId: string, fee: number) => {
        const { bill } = this.state;

        if (!bill || bill.id !== billId)
            return;

        const newBill = { ...bill, feeAmount: fee };
        this.saveBill(newBill, this.closeOverlay);
    }
    
    addDiscount = (billId: string, promotionId: string, amountOverride: number | null, callback: (success: boolean, error: api.ApiError | null) => void) => {
        const { bill } = this.state;

        if (!bill || bill.id !== billId)
            return;

        this.setState({ saving: true });
        const { venue, logout } = this.props;

        api.postWithAuth(`api/v1/bill/${billId}/discount`, { venueId: venue.id, PromotionId: promotionId, AmountOverride: amountOverride }, logout).subscribe(response => {
            const mpr = response.response as ISaveBillResponse;
            if (mpr) {
                this.setState({ saving: false, billId: mpr.bill.id, bill: deserializeBill(mpr.bill) });
                callback(true, null);
            }
        }, (err: api.ApiError) => {
            callback(false, err);
        });
    }

    replaceDiscount = (billId: string, promotionId: string, amountOverride: number | null, callback: (success: boolean, error: api.ApiError | null) => void) => {
        const { bill } = this.state;

        if (!bill || bill.id !== billId)
            return;

        this.setState({ saving: true });
        const { venue, logout } = this.props;

        api.putWithAuth(`api/v1/bill/${billId}/discount`, { venueId: venue.id, PromotionId: promotionId, AmountOverride: amountOverride }, logout).subscribe(response => {
            const mpr = response.response as ISaveBillResponse;
            if (mpr) {
                this.setState({ saving: false, billId: mpr.bill.id, bill: deserializeBill(mpr.bill) });
                callback(true, null);
            }
        }, (err: api.ApiError) => {
            callback(false, err);
        });
    }

    removeDiscount = (billId: string, billDiscountId: string, callback: (success: boolean, error: api.ApiError | null) => void) => {
        const { bill } = this.state;

        if (!bill || bill.id !== billId)
            return;

        this.setState({ saving: true });
        const { venue, logout } = this.props;

        api.putWithAuth(`api/v1/bill/${billId}/discount/remove`, { venueId: venue.id, billDiscountId: billDiscountId }, logout).subscribe(response => {
            const mpr = response.response as ISaveBillResponse;
            if (mpr) {
                this.setState({ saving: false, billId: mpr.bill.id, bill: deserializeBill(mpr.bill) });
                callback(true, null);
            }
        }, (err: api.ApiError) => {
            callback(false, err);
        });
    }

    editBillFee = (bill: Bill, fee: BillFee | null) => {
        const { fees, taxRates } = this.props;

        if (fee) {
            this.showOverlay(<EditBillFee bill={bill} fee={fee} updateBillFee={this.updateBillFee} close={this.closeOverlay} />);
        } else {
            this.showOverlay(<AddBillFee bill={bill} fees={fees} taxRates={taxRates} addFee={this.addFee} close={this.closeOverlay} />);
        }
    }

    getBillDiscountAmount = (bill: Bill) => bill.discounts.reduce((ttl, d) => ttl + d.amount, 0)

    recalculateBalanceWithDiscount = (bill: Bill, amount: number) => bill.outstandingBalance + this.getBillDiscountAmount(bill) - amount;

    editBillDiscount = (bill: Bill) => {
        this.showOverlay(<AddBillDiscount
            bill={bill}
            isMember={bill.customerHasActiveMembership}
            promotions={this.props.promotions.filter(p => p.venueId === this.props.venue.id)}
            addDiscount={bill.discounts.length === 0 ? this.addDiscount : this.replaceDiscount}
            removeDiscount={this.removeDiscount}
            close={this.closeOverlay}
            logout={this.props.logout} />);
    }

    viewVoucher = (voucher: BillItemVoucher) => {
        const { venue, logout } = this.props;

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        this.showOverlay(<VoucherDetails
            voucherId={voucher.voucherId}
            timeFormat={timeFormat}
            dateFormat={dateFormat}
            close={this.closeOverlay}
            logout={logout} />)
    }

    sendVouchers = (voucherItems: BillItem[]) => {
        const { bill } = this.state;
        const { venue, logout } = this.props;
        this.showOverlay(<SendVouchersForm
            voucherItems={voucherItems}
            venueId={venue.id}
            customerId={bill ? bill.customerId : ''}
            customerEmail={bill && bill.customerEmail ? bill.customerEmail : ''}
            close={this.closeOverlay}
            logout={logout} />)
    }

    sendMembershipEmail = (membershipItems: BillItem[]) => {
        const { bill } = this.state;
        const { venue, logout } = this.props;
        this.showOverlay(<SendMembershipEmailOverlay
            venueId={venue.id}
            membershipIds={membershipItems.map(i => i.membership ? i.membership.id : '').filter(mid => !isNullOrEmpty(mid))}
            customerName={bill ? bill.customerName : ''}
            customerEmail={bill && bill.customerEmail ? bill.customerEmail : ''}
            close={this.closeOverlay}
            logout={logout} />)
    }

    makePayment = () => this.setState({ showPayments: true, recordPayment: this.payBill, isRefund: false });

    payScheduledPayment = (payment: BillPayment) => {
        this.setState({ showPayments: true, payment: payment, recordPayment: this.payBill, isRefund: false });
    }

    refund = () => this.setState({ showPayments: true, recordPayment: (billKey: string, paymentMethod: PaymentMethod, amount: number, paymentId: string | null, text: string | null, paymentComplete: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => this.makeRefund(billKey, paymentMethod, amount, text, paymentComplete), isRefund: true });

    selectCustomer = () => this.showOverlay(<CustomerSearch customerSelected={this.contactSelected} createCustomer={this.createContact} cancel={this.closeOverlay} />);

    contactSelected = (customer: CustomerSearchResult) => {
        const { bill } = this.state;

        if (bill) {
            this.setBillCustomer(bill.id, customer.customerId, this.closeOverlay);
        }
    }

    addCustomer = (customer: CustomerDetails) => {
        const { logout, venue } = this.props;
        const { bill } = this.state;

        if (!bill) {
            return;
        }

        const call = api.postWithAuth(`api/v1/customer/contact`, {
            venueId: venue.id,
            companyName: customer.companyName,
            firstname: customer.firstname,
            lastname: customer.lastname,
            emailAddress: customer.emailAddress,
            phoneNumber: customer.phoneNumber,
            isOrganiser: customer.isOrganiser,
            addressLine1: customer.addressLine1,
            addressLine2: customer.addressLine2,
            addressLine3: customer.addressLine3,
            addressLine4: customer.addressLine4,
            town: customer.town,
            county: customer.county,
            countryId: customer.countryId,
            postalCode: customer.postalCode,
            emergencyContactName: customer.emergencyContactName,
            emergencyContactNumber: customer.emergencyContactNumber,
            marketingPreference: customer.marketingPreference,
            notes: customer.notes,
            tags: customer.tags
        }, logout);

        call.subscribe(response => {
            const ccr = response.response as ICreateContactResponse;
            if (ccr) {
                this.setBillCustomer(bill.id, ccr.customerId, this.closeOverlay);
            }
        }, (err: api.ApiError) => {
            this.setState({ saving: false, saveError: err, saveValidationErrors: err.validationErrors });
        });
    }

    editCustomer = (customer: CustomerDetails, photoImg: File | null) => {

    }

    createContact = () => {
        const customer = { key: generateTempId(), customerId: '', companyName: '', firstname: '', lastname: '', emailAddress: '', phoneNumber: '', addressLine1: '', addressLine2: '', addressLine3: '', addressLine4: '', town: '', county: '', countryId: this.props.venue.countryId, postalCode: '', isOrganiser: false, marketingPreference: MarketingPreference.None, publicResultsConsent: true, emergencyContactName: '', emergencyContactNumber: '', tags: [], notes: '', nickname: null, gender: Gender.NotProvided };
        this.showOverlay(<div style={{overflowY: 'auto', overflowX: 'hidden'}}><EventCustomerForm
            add={true}
            customer={{ ...customer, birthYear: 0, birthMonth: 0, birthDay: 0, hasActiveMembership: false }}
            requirePhoneNumber={false}
            showIsOrganiser={false}
            canChangeDob={false}
            canAddPhoto={false}
            defaultCountryId={this.props.venue.countryId}
            addCustomer={this.addCustomer}
            updateCustomer={this.editCustomer}
            cancel={this.closeOverlay} /></div>);
    }

    showOverlay = (component: JSX.Element) => this.setState({ showOverlay: true, overlayComponent: component });

    closeOverlay = () => {
        this.setState({ showOverlay: false, overlayComponent: null });
    }

    openTill = () => {
        fetch('http://localhost:6715/api/till/openDrawer', { method: 'post' })
            .then(res => { })
            .catch(e => { });
    }

    printReceipt = () => {
        const { venue } = this.props;
        const { bill } = this.state;
        const { t } = this.context;

        if (!bill) return;

        const sortedItems = bill.items.filter(i => !i.reservationId || !i.primaryBillItemId).sort((a, b) => (a.reservationStartTime ? a.reservationStartTime.getUTCMilliseconds() : 0) - (b.reservationStartTime ? b.reservationStartTime.getUTCMilliseconds() : 0));
        const first = sortedItems[0];
        const last = sortedItems[Math.max(0, sortedItems.length - 1)];
        const multiDayEvent = sortedItems.length > 1 && first.reservationStartTime && last.reservationStartTime ? first.reservationStartTime.daysDifference(last.reservationStartTime) !== 0 : false;
        const discountAmount = bill.discounts.reduce((ttl, d) => ttl + d.amount, 0);
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        const body = ({
            companyName: venue.name,
            addressLine1: venue.addressLine1,
            addressTown: venue.town,
            postCode: venue.postalCode,
            phoneNumber: venue.phoneNumber,
            taxNumber: venue.salesTaxNumber,
            billId: bill.billNumber,
            customerName: bill.customerName,
            discount: discountAmount !== 0 ? { description: 'Discount', amount: discountAmount } : null,
            totalAmount: bill.totalAmount,
            taxAmount: bill.totalTaxAmount,
            amountPaid: bill.paidAmount,
            currencySymbol: venue.currencySymbol,
            paymentMethod: bill.payments && bill.payments.length > 0 ? bill.payments[bill.payments.length - 1].paymentMethodName : '',
            taxBreakdown: bill.taxBreakdown.filter(t => t.rate > 0).map(t => ({ rate: t.rate, taxAmount: t.rateTotal })),
            items: sortedItems.map(i => ({
                quantity: i.quantity,
                productDescription: formatItemDescription(i, multiDayEvent, timeFormat, dateFormat, t),
                unitPrice: i.unitPrice,
                reservationId: i.reservationId,
                totalPrice: i.totalItemPrice
            })).concat((bill.fees || []).filter(f => !f.deleted).map(f => ({
                quantity: 1,
                productDescription: f.description,
                unitPrice: f.amount,
                reservationId: null,
                totalPrice: f.amount
            }))),
            payments: bill.payments.filter(p => p.paid && p.payentTakenDateTime).map(p => ({ paymentTime: p.payentTakenDateTime ? p.payentTakenDateTime.toYMDHMDateString() : '', amount: p.amount, paymentMethod: p.paymentMethodName }))
        });

        fetch('http://localhost:6715/api/till/printRecepit', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify(body)
        })
        .then(res => { })
        .catch(e => { });
    }

    getCategoryProducts = (venue: Venue, category: PosProductCategory): ActivityFormatProduct[] => {
        const { products, vouchers, membershipTypes, eventProducts } = this.props;

        if (category.isEventCategory) {
            return eventProducts;
        } else {
            const venueProducts = products.filter(p => p.venueId === venue.id || ((p.type === ProductType.Voucher || p.type === ProductType.Membership) && this.canSellOnPos(venue.id, p, vouchers, membershipTypes)))
            const isVoucherCategory = category.isVoucherCategory;
            const isMembershipCategory = category.isMembershipCategory;
            const categoryProducts = venueProducts.filter(p => isVoucherCategory ? p.type === ProductType.Voucher : isMembershipCategory ? p.type === ProductType.Membership : p.categoryIds.indexOf(category.id) >= 0)
            const nonActivityProducts = categoryProducts.filter(p => !p.isActivityProduct)
            return nonActivityProducts.map(p => ({ key: p.id, product: p, displayName: p.name, placesPerUnit: 0, customerCategoryId: null, customerCategoryName: null, reservationId: null, reservationTime: null }));
        }
    }

    canSellOnPos = (venueId: string, product: Product, vouchers: VoucherProduct[], membershipTypes: MembershipType[]) => {
        const voucher = vouchers.find(v => v.productId === product.id);
        if (voucher) {
            var venueSetting = voucher.venueSettings.find(vs => vs.venueId === venueId);

            return venueSetting && venueSetting.sellOnPointOfSale;
        }

        const membershipType = membershipTypes.find(mt => mt.productId === product.id);
        if (membershipType) {
            var venueSetting = membershipType.venuePurchaseSettings.find(vs => vs.venueId === venueId);

            return venueSetting && venueSetting.sellOnPointOfSale;
        }

        return false;
    }

    getSelectedCategoryName = () => {

    }

    render() {
        const { bill, showPayments, recordPayment, isRefund, productCategories, selectedCategory, overlayComponent, showOverlay } = this.state;
        const { venue, booking, paymentMethods } = this.props;
        const products = selectedCategory ? this.getCategoryProducts(venue, selectedCategory) : [];
        const overlayVisibility = showOverlay ? 'visible' : 'collapse';

        if (!bill) {
            return <Loading />
        }

        return (
            <div className='pos_wrapper' style={({ position: 'relative' })}>
                {showPayments ? this.renderPayment(booking, bill, isRefund, recordPayment, paymentMethods) : this.renderPos(selectedCategory, productCategories, products, bill)}

                <div className='pos_overlay' style={({ visibility: overlayVisibility})}>
                    <div className='pos_overlay_content'>
                        {overlayComponent}
                    </div>
                </div>
            </div>
        );
    }

    close = (bill: Bill | null) => {
        if (bill) this.props.posSessionComplete(bill);
        //this.props.close();
    }

    renderPayment = (booking: Booking | null, bill: Bill, isRefund: boolean, recordPayment: (billKey: string, paymentMethod: PaymentMethod, amount: number, paymentId: string | null, text: string | null, paymentComplete: (success: boolean, bill: Bill | null, error: api.ApiError | null) => void) => void, paymentMethods: PaymentMethod[]) => {
        const { payment, paymentInProgress, paymentError } = this.state;
        const { showPayments, paymentAmount, customerCategories, venue } = this.props;
        const closeFunc = payment || showPayments
            ? this.close
            : () => this.setState({ showPayments: false, showOverlay: false });

        const filteredPaymentMethods = (isRefund ? paymentMethods.filter(pm => pm.canRefund) : paymentMethods).filter(pm => !pm.archived && (!isNullOrEmpty(bill.bookingId) || !pm.eventPaymentsOnly));

        const hasPayment = bill && bill.payments && bill.payments.length > 0;
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        return <PointOfSalePaymentsPanel
            booking={booking}
            bill={bill}
            close={closeFunc}
            paymentInProgrss={paymentInProgress}
            isFirstPayment={!hasPayment}
            isRefund={isRefund}
            payment={payment}
            paymentAmount={paymentAmount}
            recordPayment={recordPayment}
            timeFormat={timeFormat}
            dateFormat={dateFormat}
            schedulePayment={this.schedulePayment}
            editRefund={r => this.editRefund(bill, r, timeFormat, dateFormat)}
            paymentMethods={filteredPaymentMethods}
            customerCategories={customerCategories}
            paymentError={paymentError}
            showOverlay={this.showOverlay}
            closeOverlay={this.closeOverlay}
            openTill={this.openTill} />;
    }
       
    renderPos = (selectedCategory: PosProductCategory | null, categories: PosProductCategory[], categoryProducts: ActivityFormatProduct[], bill: Bill) => {
        const { t } = this.context;
        const { customerId, venue, posSessionComplete, customerCategories, products, vouchers, membershipTypes } = this.props;
        const { tillIntegrationAvailable, saving } = this.state;
        const showRefund = bill.payments.filter(p => p.paid && !p.deleted && !p.void).length > 0;

        const voucherItems = bill.items.filter(i => i.vouchers && i.vouchers.length > 0);
        const membershipItems = bill.items.filter(i => i.membership);
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;
        const customerRequired = selectedCategory && selectedCategory.isMembershipCategory && !customerId && !bill.customerId ? true : false;

        return (
            <div className='pos_flex_wrapper'>
                <div className='pos_bill_panel'>
                    <BillPanel bill={bill}
                        bookingCancelled={false}
                        customerCategories={customerCategories}
                        timeFormat={timeFormat}
                        dateFormat={dateFormat}
                        editBillItem={itm => this.editItem(bill, itm)}
                        editPayment={p => this.editPayment(bill, p)}
                        editRefund={r => this.editRefund(bill, r, timeFormat, dateFormat)}
                        editDiscount={_ => this.editBillDiscount(bill)}
                        editBillFee={f => this.editBillFee(bill, f)}
                        makePayment={p => this.payScheduledPayment(p)}
                        viewVoucher={this.viewVoucher}
                        selectCustomer={this.selectCustomer} />

                    {voucherItems.length > 0 ? <div className='pos_action_buttons'>
                            <button className='btn btn-info pos_action_button' onClick={e => clickHandler(e, () => this.sendVouchers(voucherItems))}>{t('PointOfSale:sendVouchers')}</button>
                        </div>
                        : null}
                    {membershipItems.length > 0 ? <div className='pos_action_buttons'>
                        <button className='btn btn-info pos_action_button' onClick={e => clickHandler(e, () => this.sendMembershipEmail(membershipItems))}>{t('PointOfSale:sendMemberships')}</button>
                        </div>
                        : null}
                    <div className='pos_action_buttons'>
                        <button className='btn btn-info pos_action_button' onClick={e => clickHandler(e, () => this.editBillFee(bill, null))} disabled={saving}>{t('PointOfSale:fee')}</button>
                        <button className='btn btn-info pos_action_button' onClick={e => clickHandler(e, () => this.editBillDiscount(bill))} disabled={saving}>{t('PointOfSale:discount')}</button>
                        {showRefund ? <button className='btn btn-warning pos_action_button' onClick={e => clickHandler(e, this.refund)} disabled={saving}>{t('PointOfSale:refund')}</button> : null }
                        {tillIntegrationAvailable ? <button className='btn btn-info pos_action_button' onClick={e => clickHandler(e, this.printReceipt)} disabled={saving}>{t('PointOfSale:print')}</button> : null}
                    </div>
                    <div className='pos_action_buttons'>
                        <button className='btn btn-primary pos_action_button' onClick={e => clickHandler(e, () => posSessionComplete(bill))} disabled={saving}>{t('Global:done')}</button>
                        <button className='btn btn-success pos_action_button' onClick={e => clickHandler(e, this.makePayment)} disabled={saving}>{t('PointOfSale:takePayment')}</button>
                        {tillIntegrationAvailable ? <button className='btn btn-info pos_action_button' onClick={e => clickHandler(e, this.openTill)} disabled={saving}>{t('PointOfSale:open')}</button> : null}
                    </div>
                </div>
                <div className='pos_categories_panel'>
                    <CategoriesPanel venueId={venue.id} categories={categories} categorySelected={this.categorySelected} products={products} vouchers={vouchers} membershipTypes={membershipTypes} />
                </div>
                <div className='pos_products_panel'>
                    <ProductsPanel
                        categoryName={selectedCategory ? selectedCategory.name : ''}
                        products={categoryProducts}
                        timeFormat={timeFormat}
                        customerSelectionRequired={customerRequired}
                        productSelected={(productId: string, customerCategoryId: string | null, quantity: number, reservationId: string | null) => this.productSelected(productId, customerCategoryId, selectedCategory, quantity, reservationId)}
                    />
                </div>
            </div>
        );
    }
}

