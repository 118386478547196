
import * as PropTypes from 'prop-types'
import { AnalyticsAllProductsBucket, VenueAllProductsStats } from "./../types";
import { ITranslationContext } from '../../../../translations';
import { formatComparison } from './utils';
import { AllProductsReportSettings } from '../../../../store/pages/analytics/types';

interface AllProductsReportDataTableProps {
    settings: AllProductsReportSettings;
    venues: VenueAllProductsStats[];
    showComparison: boolean;
    formatPeriodHeading: (period: AnalyticsAllProductsBucket) => string;
}

const AllProductsReportDataTable = (props: AllProductsReportDataTableProps, context: ITranslationContext) => {
    const { settings, venues, showComparison, formatPeriodHeading } = props;
    const { t } = context;
    if (!settings.tableGrossValues && !settings.tableNetValues && !settings.tableDiscount && !settings.tableQuantity && !settings.tableBills && !settings.tableEvents && !settings.tableSessions) {
        return null;
    }

    return <>{venues
        .map(v => <div key={`${v.venueId}_table_wrapper`} className='analytics-report-data-table'>
            {venues.length > 1 ? <h4 style={{ backgroundColor: v.colour }}>{v.venueName}</h4> : null}
            <table className='table table-striped table-sm table-condensed'>
                <thead>
                    <tr key='main-header'>
                        <th></th>
                        {settings.tableGrossValues ? <th colSpan={showComparison ? 3 : 1}>{t('AllProductsReportDataTable:grossValues')}</th> : null}
                        {settings.tableNetValues ? <th colSpan={showComparison ? 3 : 1}>{t('AllProductsReportDataTable:netValues')}</th> : null}
                        {settings.tableDiscount ? <th colSpan={showComparison ? 3 : 1}>{t('AllProductsReportDataTable:discount')}</th> : null}
                        {settings.tableQuantity ? <th colSpan={showComparison ? 3 : 1}>{t('AllProductsReportDataTable:quantity')}</th> : null}
                        {settings.tableBills ? <th colSpan={showComparison ? 3 : 1}>{t('AllProductsReportDataTable:bills')}</th> : null}
                        {settings.tableEvents ? <th colSpan={showComparison ? 3 : 1}>{t('AllProductsReportDataTable:events')}</th> : null}
                        {settings.tableSessions ? <th colSpan={showComparison ? 3 : 1}>{t('AllProductsReportDataTable:sessions')}</th> : null}
                    </tr>
                    {showComparison
                        ? <tr key='sub-header'>
                            <th></th>
                            {settings.tableGrossValues ? <th className='bordered'>{t('AllProductsReportDataTable:thisPeriod')}</th> : null}
                            {settings.tableGrossValues ? <th colSpan={2}>{t('AllProductsReportDataTable:comparedTo')}</th> : null}
                            {settings.tableNetValues ? <th className='bordered'>{t('AllProductsReportDataTable:thisPeriod')}</th> : null}
                            {settings.tableNetValues ? <th colSpan={2}>{t('AllProductsReportDataTable:comparedTo')}</th> : null}
                            {settings.tableDiscount ? <th className='bordered'>{t('AllProductsReportDataTable:thisPeriod')}</th> : null}
                            {settings.tableDiscount ? <th colSpan={2}>{t('AllProductsReportDataTable:comparedTo')}</th> : null}
                            {settings.tableQuantity ? <th className='bordered'>{t('AllProductsReportDataTable:thisPeriod')}</th> : null}
                            {settings.tableQuantity ? <th colSpan={2}>{t('AllProductsReportDataTable:comparedTo')}</th> : null}
                            {settings.tableBills ? <th className='bordered'>{t('AllProductsReportDataTable:thisPeriod')}</th> : null}
                            {settings.tableBills ? <th colSpan={2}>{t('AllProductsReportDataTable:comparedTo')}</th> : null}
                            {settings.tableEvents ? <th className='bordered'>{t('AllProductsReportDataTable:thisPeriod')}</th> : null}
                            {settings.tableEvents ? <th colSpan={2}>{t('AllProductsReportDataTable:comparedTo')}</th> : null}
                            {settings.tableSessions ? <th className='bordered'>{t('AllProductsReportDataTable:thisPeriod')}</th> : null}
                            {settings.tableSessions ? <th colSpan={2}>{t('AllProductsReportDataTable:comparedTo')}</th> : null}
                        </tr>
                        : null}
                </thead>
                <tbody>
                    {v.thisPeriodStats.periodBuckets.map((ps, ix) => {
                        const comparisonValues = v.comparisonPeriodStats.periodBuckets[ix] ? v.comparisonPeriodStats.periodBuckets[ix] : null;
                        return <tr key={`${v.venueId}_${ix}`}>
                            <th className='row-heading'>{formatPeriodHeading(ps)}</th>
                            {settings.tableGrossValues ? <td className='bordered'>{ps.grossRevenue.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                            {settings.tableGrossValues && showComparison ? <td>{comparisonValues ? comparisonValues.grossRevenue.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode }) : null}</td> : null}
                            {settings.tableGrossValues && showComparison ? <td>{formatComparison(ps.grossRevenue, comparisonValues ? comparisonValues.grossRevenue : null)}</td> : null}

                            {settings.tableNetValues ? <td className='bordered'>{ps.netRevenue.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                            {settings.tableNetValues && showComparison ? <td>{comparisonValues ? comparisonValues.netRevenue.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode }) : null}</td> : null}
                            {settings.tableNetValues && showComparison ? <td>{formatComparison(ps.netRevenue, comparisonValues ? comparisonValues.netRevenue : null)}</td> : null}

                            {settings.tableDiscount ? <td className='bordered'>{ps.discount.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                            {settings.tableDiscount && showComparison ? <td>{comparisonValues ? comparisonValues.discount.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode }) : null}</td> : null}
                            {settings.tableDiscount && showComparison ? <td>{formatComparison(ps.discount, comparisonValues ? comparisonValues.discount : null)}</td> : null}

                            {settings.tableQuantity ? <td className='bordered'>{ps.quantity.toFixed(0)}</td> : null}
                            {settings.tableQuantity && showComparison ? <td>{comparisonValues ? comparisonValues.quantity.toFixed(0) : null}</td> : null}
                            {settings.tableQuantity && showComparison ? <td>{formatComparison(ps.quantity, comparisonValues ? comparisonValues.quantity : null)}</td> : null}

                            {settings.tableBills ? <td className='bordered'>{ps.bills.toFixed(0)}</td> : null}
                            {settings.tableBills && showComparison ? <td>{comparisonValues ? comparisonValues.bills.toFixed(0) : null}</td> : null}
                            {settings.tableBills && showComparison ? <td>{formatComparison(ps.bills, comparisonValues ? comparisonValues.bills : null)}</td> : null}

                            {settings.tableEvents ? <td className='bordered'>{ps.events.toFixed(0)}</td> : null}
                            {settings.tableEvents && showComparison ? <td>{comparisonValues ? comparisonValues.events.toFixed(0) : null}</td> : null}
                            {settings.tableEvents && showComparison ? <td>{formatComparison(ps.events, comparisonValues ? comparisonValues.events : null)}</td> : null}

                            {settings.tableSessions ? <td className='bordered'>{ps.sessions.toFixed(0)}</td> : null}
                            {settings.tableSessions && showComparison ? <td>{comparisonValues ? comparisonValues.sessions.toFixed(0) : null}</td> : null}
                            {settings.tableSessions && showComparison ? <td>{formatComparison(ps.sessions, comparisonValues ? comparisonValues.sessions : null)}</td> : null}
                        </tr>
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <th className='row-heading'>{t('Global:totals')}</th>
                        {settings.tableGrossValues ? <td className='bordered'>{v.thisPeriodStats.totalRevenueThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                        {settings.tableGrossValues && showComparison ? <td>{v.comparisonPeriodStats.totalRevenueThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                        {settings.tableGrossValues && showComparison ? <td>{formatComparison(v.thisPeriodStats.totalRevenueThisPeriod, v.comparisonPeriodStats.totalRevenueThisPeriod)}</td> : null}

                        {settings.tableNetValues ? <td className='bordered'>{v.thisPeriodStats.netRevenueThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                        {settings.tableNetValues && showComparison ? <td>{v.comparisonPeriodStats.netRevenueThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                        {settings.tableNetValues && showComparison ? <td>{formatComparison(v.thisPeriodStats.netRevenueThisPeriod, v.comparisonPeriodStats.netRevenueThisPeriod)}</td> : null}

                        {settings.tableDiscount ? <td className='bordered'>{v.thisPeriodStats.discountThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                        {settings.tableDiscount && showComparison ? <td>{v.comparisonPeriodStats.discountThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td> : null}
                        {settings.tableDiscount && showComparison ? <td>{formatComparison(v.thisPeriodStats.discountThisPeriod, v.comparisonPeriodStats.discountThisPeriod)}</td> : null}

                        {settings.tableQuantity ? <td className='bordered'>{v.thisPeriodStats.quantityThisPeriod.toFixed(0)}</td> : null}
                        {settings.tableQuantity && showComparison ? <td>{v.comparisonPeriodStats.quantityThisPeriod.toFixed(0)}</td> : null}
                        {settings.tableQuantity && showComparison ? <td>{formatComparison(v.thisPeriodStats.quantityThisPeriod, v.comparisonPeriodStats.quantityThisPeriod)}</td> : null}

                        {settings.tableBills ? <td className='bordered'>{v.thisPeriodStats.billsThisPeriod.toFixed(0)}</td> : null}
                        {settings.tableBills && showComparison ? <td>{v.comparisonPeriodStats.billsThisPeriod.toFixed(0)}</td> : null}
                        {settings.tableBills && showComparison ? <td>{formatComparison(v.thisPeriodStats.billsThisPeriod, v.comparisonPeriodStats.billsThisPeriod)}</td> : null}

                        {settings.tableEvents ? <td className='bordered'>{v.thisPeriodStats.eventsThisPeriod.toFixed(0)}</td> : null}
                        {settings.tableEvents && showComparison ? <td>{v.comparisonPeriodStats.eventsThisPeriod.toFixed(0)}</td> : null}
                        {settings.tableEvents && showComparison ? <td>{formatComparison(v.thisPeriodStats.eventsThisPeriod, v.comparisonPeriodStats.eventsThisPeriod)}</td> : null}

                        {settings.tableSessions ? <td className='bordered'>{v.thisPeriodStats.sessionsThisPeriod.toFixed(0)}</td> : null}
                        {settings.tableSessions && showComparison ? <td>{v.comparisonPeriodStats.sessionsThisPeriod.toFixed(0)}</td> : null}
                        {settings.tableSessions && showComparison ? <td>{formatComparison(v.thisPeriodStats.sessionsThisPeriod, v.comparisonPeriodStats.sessionsThisPeriod)}</td> : null}
                    </tr>
                </tfoot>
            </table>
        </div>)}</>
}

AllProductsReportDataTable.contextTypes = {
    t: PropTypes.func
}

export default AllProductsReportDataTable;

