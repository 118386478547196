
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as LoginActions from '../../../store/pages/login/actions';
import * as ct from '../../global/controls';

import moment from 'moment';

import { ApplicationState } from '../../../store';
import { clickHandler } from '../../../utils/util';
import { AnalyticsReport, AnalyticsReportType } from '../../../store/pages/analytics/types';


interface MappedReduxState {
}

interface Actions {
}

interface LocalProps {
    venueId: string;
    loading: boolean;
    reports: AnalyticsReport[];
    addReport: (analyticsReportType: AnalyticsReportType) => void;
    viewReport: (report: AnalyticsReport) => void;
}

type ReportSelectionProps = MappedReduxState & Actions & LocalProps;

class ReportSelection extends React.Component<ReportSelectionProps> {
    searchTimeout: NodeJS.Timeout | null;

    constructor(props: ReportSelectionProps) {
        super(props);

        this.searchTimeout = null;

        const now = moment();

        this.state = {
            dateRange: ct.asFormValue('dateRange', { from: now.clone().startOf('day'), to: now }),
            error: null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    render() {
        const { t } = this.context;
        const { loading, reports, addReport, viewReport } = this.props;

        if (loading) {
            return <ul className='analytics-report-selection'>
                <li>{t('Global:loading') }</li>
            </ul>
        }

        return <ul className='analytics-report-selection'>
            <li className='analytics-report-selection-heading'>{t('AnalyticsReportType:Revenue')}</li>
            {reports.filter(r => r.type === AnalyticsReportType.Revenue).map(r => <li className='analytics-report-selection-report'><a href='' onClick={e => clickHandler(e, () => viewReport(r))}>{r.name}</a></li>)}
            <li className='analytics-report-selection-report'><a href='' onClick={e => clickHandler(e, () => addReport(AnalyticsReportType.Revenue)) }>{t('New report')}</a></li>
            <li className='analytics-report-selection-heading'>{t('AnalyticsReportType:Products')}</li>
            {reports.filter(r => r.type === AnalyticsReportType.Products).map(r => <li className='analytics-report-selection-report'><a href='' onClick={e => clickHandler(e, () => viewReport(r))}>{r.name}</a></li>)}
            <li className='analytics-report-selection-report'><a href='' onClick={e => clickHandler(e, () => addReport(AnalyticsReportType.Products))}>{t('New report')}</a></li>
        </ul>
    }
}

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

// Wire up the React component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(ReportSelection);
