
import * as React from 'react';
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'

import * as auth from '../../../utils/auth';
import { ApplicationState } from '../../../store';
import {ITranslationContext} from '../../../translations';

interface ComponentProps {
}

interface MappedReduxProps {
    isAuthenticated: boolean;
    username?: string;
}

type NavProps = ComponentProps & MappedReduxProps;

const Nav: React.SFC<NavProps> = (props, context: ITranslationContext) => {

    if (!props.isAuthenticated) {
        return null;
    }

    const clientAdmin = auth.isClientAdmin();
    const clientAdminRoutes = clientAdmin
        ? [
            { key: 'vouchers', path: '/vouchers', icon: 'glyphicon-gift', textKey: 'Nav:Vouchers' },
            { key: 'campaigns', path: '/campaigns', icon: 'glyphicon-bullhorn', textKey: 'Nav:Campaigns' },
            { key: 'venues', path: '/venues', icon: 'glyphicon-home', textKey: 'Nav:Venues' },
            { key: 'taxRates', path: '/taxRates', icon: 'glyphicon-euro', textKey: 'Nav:TaxRates' },
            { key: 'tags', path: '/tags', icon: 'glyphicon-tags', textKey: 'Nav:Tags' },
            { key: 'users', path: '/users', icon: 'glyphicon-user', textKey: 'Nav:Users' },
            { key: 'integrations', path: '/integrations', icon: 'glyphicon-link', textKey: 'Nav:Integrations' },
            { key: 'affiliates', path: '/affiliates', icon: 'glyphicon-share-alt', textKey: 'Nav:Affiliates' },
        ]
        : [];

    const sysAdmin = auth.isSystemAdmin();
    const sysAdminRoutes = sysAdmin
        ? [
            { key: 'clients', path: '/clients', icon: 'glyphicon-briefcase', textKey: 'Nav:Clients' },
            { key: 'analytics', path: '/analytics', icon: 'glyphicon-equalizer', textKey: 'Nav:Analytics' },
            { key: 'adminTools', path: '/admintools', icon: 'glyphicon-wrench', textKey: 'Nav:AdminTools' }]
        : [];

    const baseRoutes = [
        { key: 'home', path: '/', icon: 'glyphicon-dashboard', textKey: 'Nav:Home' },
        { key: 'diary', path: '/diary', icon: 'glyphicon-calendar', textKey: 'Nav:Diary' },
        { key: 'dailySummary', path: '/dailySummary', icon: 'glyphicon-modal-window', textKey: 'Nav:DailySummary' },
        { key: 'reception', path: '/reception', icon: 'glyphicon-tasks', textKey: 'Nav:Reception' },
        { key: 'pointOfSale', path: '/pointofsale', icon: 'glyphicon-credit-card', textKey: 'Nav:PointOfSale' },
        { key: 'customers', path: '/customers', icon: 'glyphicon-user', textKey: 'Nav:Customers' },
        { key: 'memberships', path: '/memberships', icon: 'glyphicon-certificate', textKey: 'Nav:Memberships' },
        { key: 'emails', path: '/emails', icon: 'glyphicon-send', textKey: 'Nav:Emails' },
        { key: 'reports', path: '/reports', icon: 'glyphicon-stats', textKey: 'Nav:Reports' }
    ];

    const imgUrl = 'images/menubg_colour.png';

    const items = baseRoutes.concat(clientAdminRoutes).concat(sysAdminRoutes).map(r => (<li key={r.key} className='nav-item'>
        <NavLink exact to={r.path} activeClassName='active' className='nav-link' data-toggle="collapse" data-target=".navbar-collapse">
                                                                                                <span className={`glyphicon ${r.icon}`}></span>{context.t(r.textKey)}
                                                                                            </NavLink>
                                                                                        </li>));

    return (<div className='main-nav' style={({ backgroundImage: `url(${imgUrl})`})}>
        <div className='navbar'>
            <div className='clearfix'></div>
            <div className='navbar-collapse collapse'>
                <ul className='nav navbar-nav'>
                    {items}
                </ul>
            </div>
        </div>
    </div>
    );
}

Nav.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state: ApplicationState): NavProps => ({ isAuthenticated: state.login.isAuthenticated, username: state.login.username });

export default connect(mapStateToProps, {}, null, { pure: false })(Nav);