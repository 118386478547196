
import * as PropTypes from 'prop-types'

import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, BarChart, Bar } from 'recharts';

import * as api from '../../../../store/apiClient';

import { AnalyticsAllProductsBucket, VenueAllProductsStats } from './../types';
import { ITranslationContext } from '../../../../translations';
import Loading from '../../../global/loading';
import ApiError from '../../../global/apiError';
import { DateFormat } from '../../../../store/pages/venues/types';
import { monthNameAbbreviations } from '../../../../utils/extensions';
import AnalyticsReportStatVariance from './../analyticsReportStatVariance';
import AllProductsReportDataTable from './allProductsReportDataTable';
import { AllProductsReportSettings, AnalyticsBucketResolution } from '../../../../store/pages/analytics/types';


interface ProductsReportContentProps {
    dateFormat: DateFormat;
    loading: boolean;
    hasResult: boolean;
    bucketResolution: AnalyticsBucketResolution;
    settings: AllProductsReportSettings;
    venues: VenueAllProductsStats[];
    deselectedVenues: string[];
    showComparison: boolean;
    updateSettings: (newSettings: AllProductsReportSettings) => void;
    toggleVenueSelection: (venueId: string, selected: boolean) => void;
    error: api.ApiError | null;
}

const AllProductsReportContent = (props: ProductsReportContentProps, context: ITranslationContext) => {
    const { t } = context;
    const { loading, error, bucketResolution, settings, venues, deselectedVenues, showComparison, dateFormat, updateSettings, toggleVenueSelection } = props;

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <ApiError error={error} />
    }

    const graphGrossValues = () => updateSettings({ ...settings, graphGrossValues: true })
    const showGrossValuesInTable = (show: boolean) => updateSettings({ ...settings, tableGrossValues: show })
    const showNetValues = () => updateSettings({ ...settings, graphGrossValues: false })
    const showNetValuesInTable = (show: boolean) => updateSettings({ ...settings, tableNetValues: show })
    const graphDiscount = (show: boolean) => updateSettings({ ...settings, graphDiscount: show })
    const showDiscountInTable = (show: boolean) => updateSettings({ ...settings, tableDiscount: show })
    const graphQuantity = (show: boolean) => updateSettings({ ...settings, graphQuantity: show })
    const showQuantityInTable = (show: boolean) => updateSettings({ ...settings, tableQuantity: show })
    const graphBills = (show: boolean) => updateSettings({ ...settings, graphBills: show })
    const showBillsInTable = (show: boolean) => updateSettings({ ...settings, tableBills: show })
    const graphEvents = (show: boolean) => updateSettings({ ...settings, graphEvents: show })
    const showEventsInTable = (show: boolean) => updateSettings({ ...settings, tableEvents: show })
    const graphSessions = (show: boolean) => updateSettings({ ...settings, graphSessions: show })
    const showSessionsInTable = (show: boolean) => updateSettings({ ...settings, tableSessions: show })

    const selectedVenues = venues.filter(v => !deselectedVenues.includes(v.venueId))

    return <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column' }}>
        <div className='analytics-report-content-summary'>
            <table className='analytics-report-header-table'>
                <thead>
                    <tr>
                        {venues.length > 1 ? <th></th> : null}
                        <th colSpan={showComparison ? 2 : 1}>
                            <div>
                                <span>{t('AllProductsReportContent:grossTotal')}</span>
                                <i className='glyphicon glyphicon-stats' style={{ color: settings.graphGrossValues ? '#3b84f9' : '#bbb' }} onClick={graphGrossValues}></i>
                                <i className='glyphicon glyphicon-list-alt' style={{ color: settings.tableGrossValues ? '#3b84f9' : '#bbb' }} onClick={() => showGrossValuesInTable(!settings.tableGrossValues)}></i>
                            </div>
                        </th>
                        <th colSpan={showComparison ? 2 : 1}>
                            <div>
                                <span>{t('AllProductsReportContent:netTotal')}</span>
                                <i className='glyphicon glyphicon-stats' style={{ color: settings.graphGrossValues ? '#bbb' : '#3b84f9' }} onClick={showNetValues}></i>
                                <i className='glyphicon glyphicon-list-alt' style={{ color: settings.tableNetValues ? '#3b84f9' : '#bbb' }} onClick={() => showNetValuesInTable(!settings.tableNetValues)}></i>
                            </div>
                        </th>
                        <th colSpan={showComparison ? 2 : 1}>
                            <div>
                                <span>{t('AllProductsReportContent:discountTotal')}</span>
                                <i className='glyphicon glyphicon-stats' style={{ color: settings.graphDiscount ? '#bbb' : '#3b84f9' }} onClick={() => graphDiscount(!settings.graphDiscount)}></i>
                                <i className='glyphicon glyphicon-list-alt' style={{ color: settings.tableDiscount ? '#3b84f9' : '#bbb' }} onClick={() => showDiscountInTable(!settings.tableDiscount)}></i>
                            </div>
                        </th>
                        <th colSpan={showComparison ? 2 : 1}>
                            <div>
                                <span>{t('AllProductsReportContent:quantity')}</span>
                                <i className='glyphicon glyphicon-stats' style={{ color: settings.graphQuantity ? '#3b84f9' : '#bbb' }} onClick={() => graphQuantity(!settings.graphQuantity)}></i>
                                <i className='glyphicon glyphicon-list-alt' style={{ color: settings.tableQuantity ? '#3b84f9' : '#bbb' }} onClick={() => showQuantityInTable(!settings.tableQuantity)}></i>
                            </div>
                        </th>
                        <th colSpan={showComparison ? 2 : 1}>
                            <div>
                                <span>{t('AllProductsReportContent:bills')}</span>
                                <i className='glyphicon glyphicon-stats' style={{ color: settings.graphBills ? '#3b84f9' : '#bbb' }} onClick={() => graphBills(!settings.graphBills)}></i>
                                <i className='glyphicon glyphicon-list-alt' style={{ color: settings.tableBills ? '#3b84f9' : '#bbb' }} onClick={() => showBillsInTable(!settings.tableBills)}></i>
                            </div>
                        </th>
                        <th colSpan={showComparison ? 2 : 1}>
                            <div>
                                <span>{t('AllProductsReportContent:events')}</span>
                                <i className='glyphicon glyphicon-stats' style={{ color: settings.graphEvents ? '#3b84f9' : '#bbb' }} onClick={() => graphEvents(!settings.graphEvents)}></i>
                                <i className='glyphicon glyphicon-list-alt' style={{ color: settings.tableEvents ? '#3b84f9' : '#bbb' }} onClick={() => showEventsInTable(!settings.tableEvents)}></i>
                            </div>
                        </th>
                        <th colSpan={showComparison ? 2 : 1}>
                            <div>
                                <span>{t('AllProductsReportContent:sessions')}</span>
                                <i className='glyphicon glyphicon-stats' style={{ color: settings.graphSessions ? '#3b84f9' : '#bbb' }} onClick={() => graphSessions(!settings.graphSessions)}></i>
                                <i className='glyphicon glyphicon-list-alt' style={{ color: settings.tableSessions ? '#3b84f9' : '#bbb' }} onClick={() => showSessionsInTable(!settings.tableSessions)}></i>
                            </div>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {venues.map(v => <tr key={v.venueId}>
                        {venues.length > 1 ? <td><div className='analytics-report-stat-label' style={{ borderLeft: `solid 5px ${v.colour}` }}>{venues.length > 1 ? <><input id={`${v.venueId}_selection`} type='checkbox' checked={!deselectedVenues.includes(v.venueId)} style={{ margin: 0 }} onChange={e => toggleVenueSelection(v.venueId, e.currentTarget.checked)} /><label htmlFor={`${v.venueId}_selection`} style={{ margin: '0 0 0 6px' }}>{v.venueName}</label></> : null}</div></td> : null}
                        <td className='text-right'><div style={venues.length === 1 ? { borderLeft: `solid 5px ${v.colour}` } : {}}>{v.thisPeriodStats.totalRevenueThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</div></td>
                        {showComparison ? <td><AnalyticsReportStatVariance thisPeriodValue={v.thisPeriodStats.totalRevenueThisPeriod} comparisonPeriodValue={v.comparisonPeriodStats.totalRevenueThisPeriod} /></td> : null}
                        <td className='text-right'><div>{v.thisPeriodStats.netRevenueThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</div></td>
                        {showComparison ? <td><AnalyticsReportStatVariance thisPeriodValue={v.thisPeriodStats.netRevenueThisPeriod} comparisonPeriodValue={v.comparisonPeriodStats.netRevenueThisPeriod} /></td> : null}
                        <td className='text-right'><div>{v.thisPeriodStats.discountThisPeriod.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</div></td>
                        {showComparison ? <td><AnalyticsReportStatVariance thisPeriodValue={v.thisPeriodStats.discountThisPeriod} comparisonPeriodValue={v.comparisonPeriodStats.discountThisPeriod} /></td> : null}
                        <td className='text-right'><div>{v.thisPeriodStats.quantityThisPeriod.toFixed(0)}</div></td>
                        {showComparison ? <td><AnalyticsReportStatVariance thisPeriodValue={v.thisPeriodStats.quantityThisPeriod} comparisonPeriodValue={v.comparisonPeriodStats.quantityThisPeriod} /></td> : null}
                        <td className='text-right'><div>{v.thisPeriodStats.billsThisPeriod.toFixed(0)}</div></td>
                        {showComparison ? <td><AnalyticsReportStatVariance thisPeriodValue={v.thisPeriodStats.billsThisPeriod} comparisonPeriodValue={v.comparisonPeriodStats.billsThisPeriod} /></td> : null}
                        <td className='text-right'><div>{v.thisPeriodStats.eventsThisPeriod.toFixed(0)}</div></td>
                        {showComparison ? <td><AnalyticsReportStatVariance thisPeriodValue={v.thisPeriodStats.eventsThisPeriod} comparisonPeriodValue={v.comparisonPeriodStats.eventsThisPeriod} /></td> : null}
                        <td className='text-right'><div>{v.thisPeriodStats.sessionsThisPeriod.toFixed(0)}</div></td>
                        {showComparison ? <td><AnalyticsReportStatVariance thisPeriodValue={v.thisPeriodStats.sessionsThisPeriod} comparisonPeriodValue={v.comparisonPeriodStats.sessionsThisPeriod} /></td> : null}
                    </tr>)}
                </tbody>
            </table>
        </div>

         <div className='analytics-report-content-chart-wrapper'>
            <h4 className='text-center'>{t(settings.graphGrossValues ? 'AllProductsReportContent:grossRevenue' : 'AllProductsReportContent:netRevenue')}</h4>
            <ResponsiveContainer width="100%" height={250}>
                {selectedVenues.filter(v => v.thisPeriodStats.periodBuckets.length > 1).length > 0
                    ? renderLineChart(selectedVenues, bucketResolution, settings.graphGrossValues ? 'grossRevenue' : 'netRevenue', dateFormat, showComparison, (val) => formatYAxisCurrencyTick(val, selectedVenues[0].culture, selectedVenues[0].currencyCode), t)
                    : renderBarChart(selectedVenues, bucketResolution, buildBarChartData(selectedVenues, b => settings.graphGrossValues ? b.grossRevenue : b.netRevenue), dateFormat, showComparison, t)
                }
            </ResponsiveContainer>
        </div>

        {settings.graphDiscount
            ? <div className='analytics-report-content-chart-wrapper'>
                <h4 className='text-center'>{t('AllProductsReportContent:discount')}</h4>
                <ResponsiveContainer width="100%" height={250}>
                    {selectedVenues.filter(v => v.thisPeriodStats.periodBuckets.length > 1).length > 0
                        ? renderLineChart(selectedVenues, bucketResolution, 'discount', dateFormat, showComparison, (val) => val.toString(), t)
                        : renderBarChart(selectedVenues, bucketResolution, buildBarChartData(selectedVenues, b => b.discount), dateFormat, showComparison, t)
                    }
                </ResponsiveContainer>
            </div>
            : null
        }
        {settings.graphQuantity
            ? <div className='analytics-report-content-chart-wrapper'>
                <h4 className='text-center'>{t('AllProductsReportContent:quantity')}</h4>
                <ResponsiveContainer width="100%" height={250}>
                    {selectedVenues.filter(v => v.thisPeriodStats.periodBuckets.length > 1).length > 0
                        ? renderLineChart(selectedVenues, bucketResolution, 'quantity', dateFormat, showComparison, (val) => val.toString(), t)
                        : renderBarChart(selectedVenues, bucketResolution, buildBarChartData(selectedVenues, b => b.quantity), dateFormat, showComparison, t)
                    }
                </ResponsiveContainer>
            </div>
            : null
        }
        {settings.graphBills
            ? <div className='analytics-report-content-chart-wrapper'>
                <h4 className='text-center'>{t('AllProductsReportContent:bills')}</h4>
                <ResponsiveContainer width="100%" height={250}>
                    {venues.filter(v => v.thisPeriodStats.periodBuckets.length > 1).length > 0
                        ? renderLineChart(selectedVenues, bucketResolution, 'bills', dateFormat, showComparison, (val) => val.toString(), t)
                        : renderBarChart(selectedVenues, bucketResolution, buildBarChartData(selectedVenues, b => b.bills), dateFormat, showComparison, t)
                    }
                </ResponsiveContainer>
            </div>
            : null
        }
        {settings.graphEvents
            ? <div className='analytics-report-content-chart-wrapper'>
                <h4 className='text-center'>{t('AllProductsReportContent:events')}</h4>
                <ResponsiveContainer width="100%" height={250}>
                    {venues.filter(v => v.thisPeriodStats.periodBuckets.length > 1).length > 0
                        ? renderLineChart(selectedVenues, bucketResolution, 'events', dateFormat, showComparison, (val) => val.toString(), t)
                        : renderBarChart(selectedVenues, bucketResolution, buildBarChartData(selectedVenues, b => b.events), dateFormat, showComparison, t)
                    }
                </ResponsiveContainer>
            </div>
            : null
        }
        {settings.graphSessions
            ? <div className='analytics-report-content-chart-wrapper'>
                <h4 className='text-center'>{t('AllProductsReportContent:sessions')}</h4>
                <ResponsiveContainer width="100%" height={250}>
                    {venues.filter(v => v.thisPeriodStats.periodBuckets.length > 1).length > 0
                        ? renderLineChart(selectedVenues, bucketResolution, 'sessions', dateFormat, showComparison, (val) => val.toString(), t)
                        : renderBarChart(selectedVenues, bucketResolution, buildBarChartData(selectedVenues, b => b.sessions), dateFormat, showComparison, t)
                    }
                </ResponsiveContainer>
            </div>
            : null
        }

        <AllProductsReportDataTable
            settings={settings}
            showComparison={showComparison}
            venues={selectedVenues} formatPeriodHeading={ps => formatPeriodHeading(ps, bucketResolution, dateFormat, t)} />
    </div>
}

const renderLineChart = (venues: VenueAllProductsStats[], bucketResolution: AnalyticsBucketResolution, dataKey: string, dateFormat: DateFormat, showComparison: boolean, tickFormatter: (value: any, index: number) => string, t: (val: string) => string) => {
    return <LineChart margin={{ top: 20, right: 30, left: 10, bottom: 10 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
            dataKey="date"
            type="category"
            allowDuplicatedCategory={false}
            tickFormatter={(val) => formatXAxisTick(val, bucketResolution, dateFormat, t)} />
        <YAxis tickFormatter={tickFormatter} />
        {venues.map(v => <Line key={`line_${v.venueId}`} dataKey={dataKey} data={v.thisPeriodStats.periodBuckets.sort((b1, b2) => b1.date.getTime() - b2.date.getTime())} name={v.venueName} type="monotone" stroke={v.colour} dot={false} />)}
        {showComparison ? venues.map(v => <Line key={`line_comp_${v.venueId}`} dataKey={dataKey} data={v.comparisonPeriodStats.periodBuckets.sort((b1, b2) => b1.date.getTime() - b2.date.getTime())} name={v.venueName} type="monotone" stroke={v.comparisonColour} dot={false} />) : null}
    </LineChart>
}

const renderBarChart = (venues: VenueAllProductsStats[], bucketResolution: AnalyticsBucketResolution, data: any[], dateFormat: DateFormat, showComparison: boolean, t: (val: string) => string) => {
    return <BarChart margin={{ top: 20, right: 30, left: 10, bottom: 10 }} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
            dataKey="date"
            type="category"
            allowDuplicatedCategory={false}
            tickFormatter={(val) => formatXAxisTick(val, bucketResolution, dateFormat, t)} />
        <YAxis />
        {showComparison ? venues.map((v, ix) => <Bar key={`bar_comp_${v.venueId}`} dataKey={`v${ix}_comparison`} name={v.venueName} fill={v.comparisonColour} />) : null}
        {venues.map((v, ix) => <Bar key={`bar_${v.venueId}`} dataKey={`v${ix}`} name={v.venueName} fill={v.colour} />)}
    </BarChart>
}

const buildBarChartData = (venues: VenueAllProductsStats[], valueSelector: (bucket: AnalyticsAllProductsBucket) => number) => {
    var buckets = new Map<number, any>();

    for (let i = 0; i < venues.length; i++) {
        const venue = venues[i];
        for (let b = 0; b < venue.thisPeriodStats.periodBuckets.length; b++) {
            var bucket = venue.thisPeriodStats.periodBuckets[b];
            var dayStats = buckets.get(bucket.date.getTime());
            if (!dayStats) {
                dayStats = { date: bucket.date }
                buckets.set(bucket.date.getTime(), dayStats);
            }

            dayStats[`v${i}`] = valueSelector(bucket);
        }

        for (let b = 0; b < venue.comparisonPeriodStats.periodBuckets.length; b++) {
            var bucket = venue.comparisonPeriodStats.periodBuckets[b];
            var dayStats = buckets.get(bucket.date.getTime());
            if (!dayStats) {
                dayStats = { date: bucket.date }
                buckets.set(bucket.date.getTime(), dayStats);
            }

            dayStats[`v${i}_comparison`] = valueSelector(bucket);
        }
    }

    const result = Array.from(buckets.values());
    return result;
}

const formatPeriodHeading = (ps: AnalyticsAllProductsBucket, resolution: AnalyticsBucketResolution, dateFormat: DateFormat, t: (val: string) => string) => {
    switch (resolution) {
        case AnalyticsBucketResolution.Month:
            return `${t(monthNameAbbreviations[ps.date.getMonth()])} ${ps.date.getFullYear()}`;
        case AnalyticsBucketResolution.Week:
            const start = ps.date;
            const end = new Date(ps.date.getTime());
            end.setDate(end.getDate() + 6)

            const showYear = start.getFullYear() !== end.getFullYear();

            if (start.getMonth() === end.getMonth()) {
                return dateFormat === DateFormat.DMY
                    ? `${start.getDate()} - ${end.getDate()} ${t(monthNameAbbreviations[start.getMonth()])} ${showYear ? start.getFullYear() : ''}`
                    : `${t(monthNameAbbreviations[start.getMonth()])} ${start.getDate()} - ${end.getDate()} ${showYear ? start.getFullYear() : ''}`
            } else if (start.getFullYear() === end.getFullYear()) {
                return `${start.toAbbrMonthDayString(dateFormat, t)} - ${end.toAbbrMonthDayString(dateFormat, t)} ${showYear ? start.getFullYear() : ''}`
            } else {
                return `${start.toAbbrMonthDayString(dateFormat, t)} ${showYear ? start.getFullYear() : ''} - ${end.toAbbrMonthDayString(dateFormat, t)} ${showYear ? start.getFullYear() : ''}`;
            }
        default:
            return ps.date.toAbbrMonthDayString(dateFormat, t);
    }
}

const formatXAxisTick = (val: any, resolution: AnalyticsBucketResolution, dateFormat: DateFormat, t: (val: string) => string) => {
    if (val instanceof Date) {
        return resolution === AnalyticsBucketResolution.Month
            ? `${t(monthNameAbbreviations[val.getMonth()])} ${val.getFullYear()}`
            : val.toAbbrMonthDayString(dateFormat, t)
    } 
    
    return val;
}

const formatYAxisCurrencyTick = (val: any, culture: string, currency: string) => {
    if (typeof (val) === "number") {
        return val.toLocaleString(culture, { style: 'currency', currency: currency, maximumFractionDigits: 0 })
    }
    return val;
}

AllProductsReportContent.contextTypes = {
    t: PropTypes.func
}

export default AllProductsReportContent